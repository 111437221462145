import { FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessLevelFilter } from 'src/reducers/slices/FilterSlice';
import { userTypesEnum } from '../utilities/constant';

const SystemAccessLevelFilter = (props) => {
  const accessLevelFilter = useSelector((state) => state.filters.accessLevelFilter);
  const dispatch = useDispatch();

  const onChange = (event) => {
    const selectedValue = event.target.value;
    const newFilter = {
      column_name: 'type',
      type: 'exact',
      value: selectedValue,
    };
    dispatch(setAccessLevelFilter(newFilter));
    if (props?.context?.setFilters) {
      // Replace or add the new filter for upsStatus
      props.context.setFilters((prevFilters) => {
        const filteredFilters = prevFilters.filter((f) => f.column_name !== 'state');
        // Add the new filter and return the updated array
        const updatedFilters = [...filteredFilters, newFilter];
        return updatedFilters;
      });
    }
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        labelId="system-status-label"
        size="small"
        value={accessLevelFilter?.value || ''}
        onChange={onChange}
        sx={{ height: '25px', backgroundColor: 'white' }}
      >
        {userTypesEnum.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemAccessLevelFilter;
