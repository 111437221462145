export const userData = [
  {
    firstName: 'John',
    lastName: 'Doe',
    emailAddress: 'john@example.com',
    accessLevel: 'Standard User',
    phonenumber: '123-456-7890',
  },
  {
    firstName: 'Jane',
    lastName: 'Smith',
    emailAddress: 'jane@example.com',
    accessLevel: 'Primary User',
    phonenumber: '987-654-3210',
  },
  {
    firstName: 'Alice',
    lastName: 'Johnson',
    emailAddress: 'alice@example.com',
    accessLevel: 'Standard User',
    phonenumber: '555-555-5555',
  },
  {
    firstName: 'Bob',
    lastName: 'Brown',
    emailAddress: 'bob@example.com',
    accessLevel: 'Standard User',
    phonenumber: '111-222-3333',
  },
  {
    firstName: 'Emily',
    lastName: 'Davis',
    emailAddress: 'emily@example.com',
    accessLevel: 'Standard User',
    phonenumber: '444-444-4444',
  },
  {
    firstName: 'Michael',
    lastName: 'Wilson',
    emailAddress: 'michael@example.com',
    accessLevel: 'Primary User',
    phonenumber: '666-666-6666',
  },
  {
    firstName: 'Olivia',
    lastName: 'Taylor',
    emailAddress: 'olivia@example.com',
    accessLevel: 'Standard User',
    phonenumber: '777-777-7777',
  },
  {
    firstName: 'David',
    lastName: 'Martinez',
    emailAddress: 'david@example.com',
    accessLevel: 'Primary User',
    phonenumber: '888-888-8888',
  },
  {
    firstName: 'Sophia',
    lastName: 'Anderson',
    emailAddress: 'sophia@example.com',
    accessLevel: 'Standard User',
    phonenumber: '999-999-9999',
  },
  {
    firstName: 'William',
    lastName: 'Thomas',
    emailAddress: 'william@example.com',
    accessLevel: 'Standard User',
    phonenumber: '123-123-1234',
  },
  {
    firstName: 'Isabella',
    lastName: 'White',
    emailAddress: 'isabella@example.com',
    accessLevel: 'Primary User',
    phonenumber: '456-456-4567',
  },
  {
    firstName: 'James',
    lastName: 'Jackson',
    emailAddress: 'james@example.com',
    accessLevel: 'Standard User',
    phonenumber: '789-789-7890',
  },
  {
    firstName: 'Charlotte',
    lastName: 'Harris',
    emailAddress: 'charlotte@example.com',
    accessLevel: 'Standard User',
    phonenumber: '321-321-4321',
  },
  {
    firstName: 'Daniel',
    lastName: 'Nelson',
    emailAddress: 'daniel@example.com',
    accessLevel: 'Standard User',
    phonenumber: '654-654-7654',
  },
  {
    firstName: 'Emma',
    lastName: 'Garcia',
    emailAddress: 'emma@example.com',
    accessLevel: 'Primary User',
    phonenumber: '987-987-0987',
  },
];

export const viewSystemData = [
  {
    inputVoltage: '110V',
    inputFaultVoltage: '115V',
    outputVoltage: '100V',
    outputCurrent: '8A',
    outputFrequency: '50Hz',
    batteryVoltage: '11V',
    temperature: '30°C',
    utilityFail: 'Yes',
    batteryLow: 'No',
    upsFailed: 'Yes',
    upsNormal: '1',
    upsFanlocak: '0',
    upsOverload: '1',
    upsShortCircuit: '1',
    upsBadBattery: '1',
    inverterCharging: '0',
    inverterNotCharging: '1',
  },
  {
    inputVoltage: '118V',
    inputFaultVoltage: '123V',
    outputVoltage: '108V',
    outputCurrent: '7A',
    outputFrequency: '55Hz',
    batteryVoltage: '10.8V',
    temperature: '29°C',
    utilityFail: 'No',
    batteryLow: 'Yes',
    upsFailed: 'No',
    upsNormal: '0',
    upsFanlocak: '1',
    upsOverload: '1',
    upsShortCircuit: '1',
    upsBadBattery: '0',
    inverterCharging: '1',
    inverterNotCharging: '0',
  },
  {
    inputVoltage: '115V',
    inputFaultVoltage: '120V',
    outputVoltage: '105V',
    outputCurrent: '9A',
    outputFrequency: '55Hz',
    batteryVoltage: '10.5V',
    temperature: '28°C',
    utilityFail: 'No',
    batteryLow: 'No',
    upsFailed: 'No',
    upsNormal: '1',
    upsFanlocak: '0',
    upsOverload: '0',
    upsShortCircuit: '1',
    upsBadBattery: '1',
    inverterCharging: '0',
    inverterNotCharging: '1',
  },
  {
    inputVoltage: '125V',
    inputFaultVoltage: '130V',
    outputVoltage: '115V',
    outputCurrent: '12A',
    outputFrequency: '65Hz',
    batteryVoltage: '12.5V',
    temperature: '27°C',
    utilityFail: 'Yes',
    batteryLow: 'Yes',
    upsFailed: 'Yes',
    upsNormal: '1',
    upsFanlocak: '1',
    upsOverload: '1',
    upsShortCircuit: '0',
    upsBadBattery: '1',
    inverterCharging: '0',
    inverterNotCharging: '1',
  },
  {
    inputVoltage: '105V',
    inputFaultVoltage: '110V',
    outputVoltage: '95V',
    outputCurrent: '6A',
    outputFrequency: '45Hz',
    batteryVoltage: '9.5V',
    temperature: '32°C',
    utilityFail: 'No',
    batteryLow: 'Yes',
    upsFailed: 'No',
    upsNormal: '1',
    upsFanlocak: '1',
    upsOverload: '0',
    upsShortCircuit: '1',
    upsBadBattery: '0',
    inverterCharging: '0',
    inverterNotCharging: '1',
  },
];

export const DealerManagementData = [
  {
    companyName: 'ABC Inc.',
    companyPhoneNo: '123-456-7890',
    companyEmail: 'info@abcinc.com',
    status: 'Active',
  },
  {
    companyName: 'XYZ Corp.',
    companyPhoneNo: '987-654-3210',
    companyEmail: 'info@xyzcorp.com',
    status: 'Inactive',
  },
  {
    companyName: 'PQR Ltd.',
    companyPhoneNo: '555-555-5555',
    companyEmail: 'info@pqrltd.com',
    status: 'Active',
  },
  {
    companyName: 'LMN Enterprises',
    companyPhoneNo: '111-222-3333',
    companyEmail: 'info@lmnenterprises.com',
    status: 'Inactive',
  },
  {
    companyName: 'JKL Industries',
    companyPhoneNo: '444-555-6666',
    companyEmail: 'info@jklindustries.com',
    status: 'Active',
  },
  {
    companyName: 'GHI Solutions',
    companyPhoneNo: '777-888-9999',
    companyEmail: 'info@ghisolutions.com',
    status: 'Inactive',
  },
  {
    companyName: 'DEF Services',
    companyPhoneNo: '333-222-1111',
    companyEmail: 'info@defservices.com',
    status: 'Active',
  },
  {
    companyName: 'MNO Innovations',
    companyPhoneNo: '666-777-8888',
    companyEmail: 'info@mnoinnovations.com',
    status: 'Inactive',
  },
  {
    companyName: 'STU Technologies',
    companyPhoneNo: '999-888-7777',
    companyEmail: 'info@stutechnologies.com',
    status: 'Active',
  },
  {
    companyName: 'VWX Enterprises',
    companyPhoneNo: '555-444-3333',
    companyEmail: 'info@vwxenterprises.com',
    status: 'Inactive',
  },
];

export const deviceData = [
  {
    id: 1,
    deviceName: 'Smartphone',
    serialNumber: 'ABC123',
    MACAddress: '12:34:56:78:90:AB',
    servicePartner: 'Service Partner 1',
    customerName: 'John Doe',
    modelNumber: 'SM-G950F',
    wifiModuleAddress: '12:34:56:78:90:AB',
    activationDate: '2023-01-01',
    uploadDate: '2023-01-02',
    batteryStatus: '100%',
  },
  {
    id: 2,
    deviceName: 'Laptop',
    serialNumber: 'DEF456',
    MACAddress: '98:76:54:32:10:FE',
    servicePartner: 'Service Partner 2',
    customerName: 'Jane Smith',
    modelNumber: 'HP Pavilion 15',
    wifiModuleAddress: '98:76:54:32:10:FE',
    activationDate: '2023-02-01',
    uploadDate: '2023-02-02',
    batteryStatus: '80%',
  },
  {
    id: 3,
    deviceName: 'Tablet',
    serialNumber: 'GHI789',
    MACAddress: 'AB:CD:EF:12:34:56',
    servicePartner: 'Service Partner 3',
    customerName: 'Alice Johnson',
    modelNumber: 'iPad Air',
    wifiModuleAddress: 'AB:CD:EF:12:34:56',
    activationDate: '2023-03-01',
    uploadDate: '2023-03-02',
    batteryStatus: '50%',
  },
  {
    id: 4,
    deviceName: 'Desktop',
    serialNumber: 'JKL012',
    MACAddress: '67:89:AB:CD:EF:01',
    servicePartner: 'Service Partner 4',
    customerName: 'Bob Brown',
    modelNumber: 'Dell Inspiron',
    wifiModuleAddress: '67:89:AB:CD:EF:01',
    activationDate: '2023-04-01',
    uploadDate: '2023-04-02',
    batteryStatus: '30%',
  },
  {
    id: 5,
    deviceName: 'Smartwatch',
    serialNumber: 'MNO345',
    MACAddress: '23:45:67:89:AB:CD',
    servicePartner: 'Service Partner 5',
    customerName: 'Emily Davis',
    modelNumber: 'Apple Watch Series 6',
    wifiModuleAddress: '23:45:67:89:AB:CD',
    activationDate: '2023-05-01',
    uploadDate: '2023-05-02',
    batteryStatus: '90%',
  },
  {
    id: 6,
    deviceName: 'Router',
    serialNumber: 'PQR678',
    MACAddress: '89:AB:CD:EF:01:23',
    servicePartner: 'Service Partner 6',
    customerName: 'Michael Wilson',
    modelNumber: 'Linksys EA7500',
    wifiModuleAddress: '89:AB:CD:EF:01:23',
    activationDate: '2023-06-01',
    uploadDate: '2023-06-02',
    batteryStatus: 'N/A',
  },
  {
    id: 7,
    deviceName: 'Printer',
    serialNumber: 'STU901',
    MACAddress: '45:67:89:AB:CD:EF',
    servicePartner: 'Service Partner 7',
    customerName: 'Olivia Taylor',
    modelNumber: 'HP OfficeJet Pro 9025',
    wifiModuleAddress: '45:67:89:AB:CD:EF',
    activationDate: '2023-07-01',
    uploadDate: '2023-07-02',
    batteryStatus: 'N/A',
  },
  {
    id: 7,
    deviceName: 'Camera',
    serialNumber: 'VWX234',
    MACAddress: '01:23:45:67:89:AB',
    servicePartner: 'Service Partner 8',
    customerName: 'David Martinez',
    modelNumber: 'Nikon D750',
    wifiModuleAddress: '01:23:45:67:89:AB',
    activationDate: '2023-08-01',
    uploadDate: '2023-08-02',
    batteryStatus: 'N/A',
  },
  {
    id: 8,
    deviceName: 'Speaker',
    serialNumber: 'YZA567',
    MACAddress: 'EF:01:23:45:67:89',
    servicePartner: 'Service Partner 9',
    customerName: 'Sophia Anderson',
    modelNumber: 'Sonos One',
    wifiModuleAddress: 'EF:01:23:45:67:89',
    activationDate: '2023-09-01',
    uploadDate: '2023-09-02',
    batteryStatus: 'N/A',
  },
  {
    id: 9,
    deviceName: 'Monitor',
    serialNumber: 'BCD890',
    MACAddress: 'CD:EF:01:23:45:67',
    servicePartner: 'Service Partner 10',
    customerName: 'William Thomas',
    modelNumber: 'Dell U2719D',
    wifiModuleAddress: 'CD:EF:01:23:45:67',
    activationDate: '2023-10-01',
    uploadDate: '2023-10-02',
    batteryStatus: 'N/A',
  },
];

export const upsStatsEnum = [
  {
    value: 1,
    label: 'NORMAL',
  },
  {
    value: 2,
    label: 'ON BATTERY',
  },
  {
    value: 3,
    label: 'OFFLINE',
  },
];

export const batteryBeeperStatsEnum = [
  {
    value: '0',
    label: 'Connected',
  },
  {
    value: '1',
    label: 'Disconnected',
  },
  {
    value: 'no_battery',
    label: 'Not Activated',
  },
];

export const userTypesEnum = [
  { value: 3, label: 'Standard User' },
  { value: 4, label: 'Primary Admin' },
];
