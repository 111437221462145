import { FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setBatteryStatusFilter } from 'src/reducers/slices/FilterSlice';
import { batteryBeeperStatsEnum } from '../utilities/constant';

const SystemBatteryStatusFilter = (props) => {
  const batteryStatusFilter = useSelector((state) => state.filters.batteryStatusFilter);
  const dispatch = useDispatch();

  const onChange = (event) => {
    const selectedValue = event.target.value;
    const newFilter = {
      column_name: 'battery_status_beeper_on',
      type: 'exact',
      value: selectedValue === 'no_battery' ? null : Number(selectedValue), // Convert back to null if selected
    };
    dispatch(setBatteryStatusFilter(newFilter));
    if (props?.context?.setFilters) {
      // Replace or add the new filter for upsStatus
      props.context.setFilters((prevFilters) => {
        const filteredFilters = prevFilters.filter(
          (f) => f.column_name !== 'battery_status_beeper_on',
        );
        const updatedFilters = [...filteredFilters, newFilter];
        return updatedFilters;
      });
    }
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <Select
        labelId="system-status-label"
        size="small"
        value={
          batteryStatusFilter
            ? batteryStatusFilter.value === null
              ? 'no_battery'
              : batteryStatusFilter.value
            : ''
        }
        onChange={onChange}
        sx={{ height: '25px', backgroundColor: 'white' }}
      >
        <MenuItem value={''} disabled>
          SELECT STATUS
        </MenuItem>
        {batteryBeeperStatsEnum.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemBatteryStatusFilter;
