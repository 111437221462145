import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useParams } from 'react-router';
import { getUserDevice, viewSystem } from 'src/ApiCalls/SystemMangementApiCalls';
import PageContainer from 'src/components/container/PageContainer';
import BackButton from 'src/shared/BackButton';
import Pagination from 'src/shared/Pagination';
import { ViewSystemtableColDef } from '../utilities/helpers';

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  filter: true,
  resizable: true,
  wrapText: true,
  autoHeight: true,
  cellStyle: { display: 'flex', alignItems: 'center', fontFamily: 'Poppins, sans-serif' },
  wrapHeaderText: true,
  autoHeaderHeight: true,
};

const StatisticCard = ({ title, value, description, icon, days = false }) => {
  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      {value !== null ? (
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              {icon}
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" fontSize={14}>
                {title}
              </Typography>
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                spacing={1}
              >
                <Typography variant="h4" fontSize={20}>
                  {value}
                </Typography>
                {days && (
                  <Typography variant="body2" fontSize={14}>
                    (days)
                  </Typography>
                )}
              </Stack>
              <Typography variant="caption" color="textSecondary">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={85} />
      )}
    </Card>
  );
};

const SystemManagmentDetails = () => {
  const [systemData, setSystemData] = useState();
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });
  const [deviceStats, setDeviceStats] = useState(null);

  const { id: systemID } = useParams();
  const [params, setParams] = useState({
    page: 1,
    pageSize: 50,
  });
  const [type, setType] = useState('FILTER');

  const handleChange = (key, value) => {
    if (key !== 'pageSize') setParams({ ...params, [key]: value });
    else
      setParams({
        page: 1,
        pageSize: value,
      });
  };
  useEffect(() => {
    (async () => {
      const response = await viewSystem({
        page: params.page,
        page_size: params.pageSize,
        systemID,
        type,
        dateFilter,
      });

      if (response) {
        setType('FILTER');
        if (type === 'FILTER') setSystemData(response);
      }
    })();
  }, [systemID, type, params, dateFilter]);

  useEffect(() => {
    if (systemID) {
      (async () => {
        const response = await getUserDevice(systemID);
        if (response) {
          setDeviceStats(response);
        }
      })();
    }
  }, [systemID]);

  return (
    <PageContainer title="Devices" description="this is syestem view page">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          component="div"
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
          alignItems={{ xs: 'start', sm: 'center' }}
        >
          <Box component="div" mb={{ xs: 2, sm: 0 }}>
            <Typography variant="h3">
              {' '}
              <BackButton /> System Statistics
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={0} md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={6}>
              <StyledBox>
                <Typography variant="caption" display="block" gutterBottom>
                  Filter by Date
                </Typography>
                <DateRangePicker
                  startDateId="your_unique_start_date_id"
                  endDateId="your_unique_end_date_id"
                  startDate={dateFilter?.startDate}
                  endDate={dateFilter?.endDate}
                  onDatesChange={({ startDate, endDate }) => {
                    setDateFilter({ startDate, endDate });
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  displayFormat="DD-MMM-YYYY"
                  small
                  customArrowIcon={<span>—</span>}
                />
              </StyledBox>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                fullWidth
                startIcon={<ArrowDownwardIcon />}
                sx={{ mr: 2 }}
                onClick={() => setType('EXPORT')}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Stats cards */}
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatisticCard
              title="Total Runtime"
              days
              value={deviceStats ? (deviceStats.totalRunTime / 60 / 24).toFixed(1) : null}
              icon={<AccessTimeIcon fontSize="large" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatisticCard
              title="Total Hours of Backup"
              value={deviceStats ? deviceStats.totalHoursOfBackup : null}
              icon={<BatteryChargingFullIcon fontSize="large" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatisticCard
              title="Number of Events"
              value={deviceStats ? deviceStats.numberOfEvents : null}
              icon={<ReportProblemIcon fontSize="large" />}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 6 }}>
        <div className="ag-theme-alpine" style={{ margin: '10px 0' }}>
          <AgGridReact
            rowData={systemData?.data}
            defaultColDef={defaultColDef}
            columnDefs={ViewSystemtableColDef}
            //suppressScrollOnNewData={true}
            domLayout="autoHeight"
          />
        </div>
        {systemData?.data ? (
          <Pagination
            currentPage={systemData?.currentPage}
            totalPages={systemData?.totalPages}
            pageSize={systemData?.pageSize}
            handleChange={handleChange}
          />
        ) : null}
      </Box>
    </PageContainer>
  );
};

export default SystemManagmentDetails;
const StyledBox = styled(Grid)(({ theme }) => ({
  position: 'relative',
  '& .MuiTypography-root': {
    position: 'absolute',
    left: '10px',
    top: '-12px',
    fontSize: '10px',
    color: '#00000099',
    paddingLeft: '4px',
    paddingRight: '4px',
    zIndex: '100',
  },
  '& .DateRangePicker': {
    width: '100%',
    '& .DateRangePickerInput': {
      width: '100%',
      background: '#ffffff',
      border: ' 1px double #c0c0c0',
      borderRadius: '4px',
      '& .DateInput': {
        background: 'unset',
        width: '48%',
        '& .DateInput_input': {
          background: 'unset',
          font: 'unset',
          textAlign: 'center',
          padding: '14px 4px',
        },
        '& .DateInput_input__focused': {
          borderBottom: '2px solid',
          borderBottomColor: theme.palette.primary.main,
        },
      },
      '& .DateRangePicker_picker': {
        '& .DayPicker': {
          '& .DayPicker_focusRegion': {
            '& .DayPicker_transitionContainer': {
              '& .CalendarMonthGrid': {
                '& .CalendarMonth': {
                  '& .CalendarMonth_table': {
                    '& .CalendarDay__hovered_span': {
                      background: theme.palette.primary.light,
                      color: 'white',
                    },
                    '& .CalendarDay__selected_start': {
                      background: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                    },
                    '& .CalendarDay__selected_span': {
                      background: theme.palette.primary.light,
                      borderColor: theme.palette.primary.light,
                    },
                    '& .CalendarDay__selected_end': {
                      background: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}));
